<template>
   <v-container fluid style="height:100%;max-width:3600px;background-color:#F0F0F0;">
      <!-- Report Builder -->
      <v-row v-if="showProfileManager" justify='center'
         class="px-0 ma-0 mt-0 pb-0 pt-0">
         <v-col 
            :class="{'pa-0 pb-4': $vuetify.breakpoint.smAndDown }"
            style='min-width:300px;max-width:860px;'>
            <v-card >
               <v-card-title class="py-1" style='background-color:#1565C0;color:white;'>
                  <v-btn icon 
                     @click="gotoLightHome"
                     color="white" class="mr-2">
                     <v-icon>mdi-arrow-left</v-icon>
                  </v-btn>
                  Lịch sử chiếu sáng
               </v-card-title>

               <v-card-text 
                  :class="{'pa-0': $vuetify.breakpoint.smAndDown }"
                  style='min-height:200px;border-bottom: 1px solid #CCCCCC;'>

                  <v-container fluid style="font-size:18px;">
                     <v-row class="pa-0 ma-0" justify='start'>
                        <v-col v-if="1==2"
                           cols='12' xs="12" sm="12" md="2" lg="2" xl="2"
                           style="" class="pa-1"
                           :class="{'reportLabel': $vuetify.breakpoint.mdAndUp }">
                           Ngày
                        </v-col>

                        <v-col cols='12' xs="12" sm="12" md="12" lg="12" xl="12"
                           class="pa-1"
                           style="">
                           <date-picker v-model='dateType' @dateChanged='onDateChanged'/>
                        </v-col>
                     </v-row>

                     <v-row v-if="1==2"  class="pa-0 ma-0" justify='start'>
                        <v-col
                           cols='6' xs="6" sm="6" md="3" lg="3" xl="3"
                           style="" class="pa-1 reportLabel">
                           Data Interval
                        </v-col>

                        <v-col
                           cols='6' xs="6" sm="6" md="9" lg="9" xl="9"
                           class="pa-1"
                           style="">
                           <v-select
                              :items="allIntervals"
                              v-model="activeInterval"
                              item-text ="label"
                              item-value ="valueInMin"
                              label="" color="primary"
                              style="width:100%;max-width:315px;border-width: 1.5px;"
                              outlined dense single-line hide-details
                           >
                              <template v-slot:selection="{ item }">
                                 <div style="text-align:left;padding-left:8px;width:100%;">
                                    {{item.label}}
                                 </div>
                              </template>
                           </v-select>
                        </v-col>
                     </v-row>

                     <v-row v-if="1==2"  class="pa-0 ma-0" justify='start'>
                        <v-col
                           cols='6' xs="6" sm="6" md="3" lg="3" xl="3"
                           style="" class="reportLabel pa-1 py-0 my-0">
                           Build Chart
                        </v-col>

                        <v-col
                           cols='6' xs="6" sm="6" md="9" lg="9" xl="9"
                           class="pa-0 my-0"
                           style="">
                           <v-checkbox
                              v-model="isBuildChart"
                              label=""
                              style=""
                              hide-details class="my-0"
                           ></v-checkbox>
                        </v-col>

                        <v-col cols='6' xs="6" sm="6" md="3" lg="3" xl="3"
                           style="" class="reportLabel pa-1 py-0 my-0">
                           Build Table
                        </v-col>

                        <v-col cols='6' xs="6" sm="6" md="9" lg="9" xl="9"
                           class="pa-0 my-0"
                           style="">
                           <v-checkbox
                              v-model="isBuildTable"
                              label=""
                              hide-details class="my-0"
                           ></v-checkbox>
                        </v-col>
                     </v-row>

                     <v-row v-if="1==2" class="pa-0 ma-0" justify='start'>
                        <v-col cols='3'
                           style="" class="reportLabel pa-1">
                           Data Points
                        </v-col>

                        <v-col cols='9' class="pa-1">
                           <point-list 
                              :subDevList='subDevList'
                              :sourcePoints='sourcePoints'
                              :pmsCustom='pmsCustom'
                              @notifySelectChange="onSelectPointChanged"
                              v-model="points"/>
                        </v-col>
                        <v-col cols="12">
                           <v-progress-linear v-if="loading"
                              indeterminate
                              color="teal"
                           ></v-progress-linear>
                        </v-col>
                     </v-row>

                     <v-row v-if="1==1" class="pa-0 ma-0" justify='start'>
                        <v-col cols='12' class="pa-1">
                           <div style="font-weight:500;color:black;border-bottom:1px solid #DDDDDD;">
                              Chọn đèn:
                           </div>
                           <v-row class="pt-4 px-4">
                              <v-col cols="12" md="6" lg="4" xl="4"
                                 class="pa-0"
                                 v-for="(point, index) in points"
                                 :key="index">
                                 <v-checkbox
                                    v-model="point.active"
                                    :label="point.name"
                                    color="teal"
                                    hide-details
                                    class="pa-0 ma-0 mt-1"
                                 ></v-checkbox>
                              </v-col>
                           </v-row>
                           
                        </v-col>
                     </v-row>

                  </v-container>

               </v-card-text>

               <v-card-actions>
                  <v-spacer/>
                  <v-btn color="primary" 
                     class="px-4 text-none"
                     @click="buildReport(1)"
                     :disabled="!(isBuildChart || isBuildTable) || !points || points.length<=0"
                     >
                     Xem Lịch Sử
                  </v-btn>
               </v-card-actions>
            </v-card>
         </v-col>
      </v-row>
      <!-- Chart -->
      <v-row v-if="showChart && !loading">
         <template v-for="(point, index) in points">
            <v-col cols="12" class="pt-2 pb-0"
               v-if="point.active"
               :key="index">
               <v-card class="pa-0">
                  <v-card-title class="pt-0 pb-0 ma-0" 
                     style="font-size:16px;">
                     <v-btn v-if="1==2" 
                        color="primary" class="text-none px-2" 
                        @click="exportExcel">
                        <v-icon class="mr-1">save_alt</v-icon>
                        Download
                     </v-btn>
                     <v-spacer/>
                        {{point.name}}
                     <v-spacer/>
                  </v-card-title>
                  <v-card-text class='pa-0 pb-0 pt-0 ma-0'>
                     <line-chart-points
                        height="100"
                        :loadIndex="loadIndex"
                        :scale='chartScale'
                        :labels='chartLabels'
                        :points='pointsWithData.slice(index, index + 1)'/>
                  </v-card-text>
               </v-card>
            </v-col>
         </template>
      </v-row>
      <!-- Table -->
      <v-row class="pa-1" v-if="showTable && !loading">
         <v-col cols='12' class='pa-0'>
            
            <v-card class="ma-0 pa-1 mt-2" v-if="tableItems">
               <v-card-title class="pt-1 pb-2 ma-0">
                  <v-btn color="primary" class="text-none" @click="exportExcel">
                     <v-icon class="mr-1">save_alt</v-icon>
                     Excel Export
                  </v-btn>
                  <v-spacer/>
               </v-card-title>
              <v-card-text class="ma-0 pt-0 px-2">
                  <v-data-table
                     dense
                     :headers="tableHeader"
                     :items="tableItems"
                     :items-per-page="35"
                     class="elevation-1"
                     :mobile-breakpoint="0"
                  ></v-data-table>
              </v-card-text>
            </v-card>
         </v-col>
      </v-row>
   </v-container>
</template>

<script>
import { mapGetters } from "vuex"
let moment = require('moment')
let XLSX = require("xlsx")

export default {
   props: ['title', 'pageKey', 'subDevList', 'pmsCustom'],
   computed: {
      ...mapGetters({
        userId: "userId",
        token: "token",
        role: "getLevel",
        fullname: "getFullName",
      }),
      hasChartContent: function () {
         if (this.pointsWithData && this.pointsWithData.length>0){
               return true
            }
         else {
            return false
         }
      },
   },
   data () {
      return {
         loading: false,
         showTable: false,
         showChart: false,
         showProfileManager: true,
         loadIndex: new Date().getTime(),
         dateType: null,
         points: [
            {
               reportName: 'light_gxe_L6',
               name: 'Cổng Chính: lối vào',
               unit: '',
               color: "#43A047",
               backgroundColor: "#43A04744",
               active: true,
               scale: 1
            },
            {
               reportName: 'light_bv_L1',
               name: 'Cổng Chính: HL Trệt',
               unit: '',
               color: "#43A047",
               backgroundColor: "#43A04744",
               active: false,
               scale: 1
            },
            {
               reportName: 'light_bv_L2',
               name: 'Cổng Chính: Thang Trệt',
               unit: '',
               color: "#43A047",
               backgroundColor: "#43A04744",
               active: false,
               scale: 1
            },
            {
               reportName: 'light_bv_L3',
               name: 'Cổng Chính: HL Lửng',
               unit: '',
               color: "#43A047",
               backgroundColor: "#43A04744",
               active: false,
               scale: 1
            },
            {
               reportName: 'light_bv_L5',
               name: 'Cổng Chính: Thang Lửng',
               unit: '',
               color: "#43A047",
               backgroundColor: "#43A04744",
               active: false,
               scale: 1
            },
            {
               reportName: 'light_bv_L4',
               name: 'Cổng Chính: HL Tầng 2',
               unit: '',
               color: "#43A047",
               backgroundColor: "#43A04744",
               active: false,
               scale: 1
            },
            {
               reportName: 'light_bv_L6',
               name: 'Cổng Chính: HL Tầng 3',
               unit: '',
               color: "#43A047",
               backgroundColor: "#43A04744",
               active: false,
               scale: 1
            },
            {
               reportName: 'light_bv_L7',
               name: 'Cổng Chính: Thang Tầng 3',
               unit: '',
               color: "#43A047",
               backgroundColor: "#43A04744",
               active: false,
               scale: 1
            },
            {
               reportName: 'light_vp_L1',
               name: 'Văn Phòng: Thang Trệt',
               unit: '',
               color: "#43A047",
               backgroundColor: "#43A04744",
               active: false,
               scale: 1
            },
            {
               reportName: 'light_vp_L2',
               name: 'Văn Phòng: HL Trệt',
               unit: '',
               color: "#43A047",
               backgroundColor: "#43A04744",
               active: false,
               scale: 1
            },
            {
               reportName: 'light_vp_L3',
               name: 'Văn Phòng: HL Toilet Trệt',
               unit: '',
               color: "#43A047",
               backgroundColor: "#43A04744",
               active: false,
               scale: 1
            },
            {
               reportName: 'light_vp_L4',
               name: 'Văn Phòng: HL Tầng 2',
               unit: '',
               color: "#43A047",
               backgroundColor: "#43A04744",
               active: false,
               scale: 1
            },
            {
               reportName: 'light_vp_L7',
               name: 'Văn Phòng: Thang Tầng 2',
               unit: '',
               color: "#43A047",
               backgroundColor: "#43A04744",
               active: false,
               scale: 1
            },
            {
               reportName: 'light_vp_L8',
               name: 'Văn Phòng: HL Tầng 3',
               unit: '',
               color: "#43A047",
               backgroundColor: "#43A04744",
               active: false,
               scale: 1
            },
            {
               reportName: 'light_vp_L7',
               name: 'Văn Phòng: Thang Tầng 3',
               unit: '',
               color: "#43A047",
               backgroundColor: "#43A04744",
               active: false,
               scale: 1
            },
            {
               reportName: 'light_vp_L6',
               name: 'Văn Phòng: HL Tầng 4',
               unit: '',
               color: "#43A047",
               backgroundColor: "#43A04744",
               active: false,
               scale: 1
            },
            {
               reportName: 'light_vp_L5',
               name: 'Văn Phòng: Thang Tầng 4',
               unit: '',
               color: "#43A047",
               backgroundColor: "#43A04744",
               active: false,
               scale: 1
            },
            {
               reportName: 'light_gxe_L8',
               name: 'Love Studio: HL Trệt',
               unit: '',
               color: "#43A047",
               backgroundColor: "#43A04744",
               active: false,
               scale: 1
            },
            {
               reportName: 'light_gxe_L5',
               name: 'Love Studio: Thang Trệt',
               unit: '',
               color: "#43A047",
               backgroundColor: "#43A04744",
               active: false,
               scale: 1
            },
            {
               reportName: 'light_gxe_L1',
               name: 'Love Studio: HL Tầng 2',
               unit: '',
               color: "#43A047",
               backgroundColor: "#43A04744",
               active: false,
               scale: 1
            },
            {
               reportName: 'light_gxe_L5',
               name: 'Love Studio: Thang Tầng 2',
               unit: '',
               color: "#43A047",
               backgroundColor: "#43A04744",
               active: false,
               scale: 1
            },
            {
               reportName: 'light_gxe_L4',
               name: 'Love Studio: HL Tầng 3',
               unit: '',
               color: "#43A047",
               backgroundColor: "#43A04744",
               active: false,
               scale: 1
            },
            {
               reportName: 'light_gxe_L2',
               name: 'Giếng Trời',
               unit: '',
               color: "#43A047",
               backgroundColor: "#43A04744",
               active: false,
               scale: 1
            },
         ],
         curDateObject: null,
         sourcePoints: [],
         activeInterval: 1,
         allIntervals: [
            { label: '1 minute', valueInMin: 1},
            { label: '5 minutes', valueInMin: 5},
            { label: '10 minutes', valueInMin: 10},
            { label: '15 minutes', valueInMin: 15},
            { label: '30 minutes', valueInMin: 30},
            { label: '1 hour', valueInMin: 60},
            { label: '2 hours', valueInMin: 120},
            { label: '6 hours', valueInMin: 360}
         ],
         chartColors: [
            '#F50057',
            '#5E35B1',
            '#0097A7',
            '#4CAF50',
            '#9E9D24',
            '#FF9800',
            '#795548',
            '#D84315',
            '#607D8B',
            '#757575',
            '#303F9F',
            '#880E4F',
            '#B71C1C'],
         pointsWithData: null,
         chartLabels: [],
         chartScale: 1,
         tableHeader: [],
         tableItems: [],
         isBuildChart: true,
         isBuildTable: false,
         blocks: [],
         reportType: 0,
         socketAskKey: new Date().getTime()
      }
   },
   sockets: {
      callHTTPRequestResult: function (payload) {
         console.log("callHTTPRequestResult")
         console.log(payload)
         if(payload && payload.askKey == this.socketAskKey) {
            if(payload.result && payload.result.status=="OK"){
               this.blocks = this.blocks.concat(payload.result.content)
               this.processDataBlocksAfterFetch()
            }
         }
      }
   },
   methods: {
      gotoLightHome(){
         // window.location ="/light"
         this.$router.push({ path: '/light' })
      },
      onDateChanged (dateObj) {
         this.curDateObject = dateObj
      },
      async fetchData(){
         //return new Promise (async (resolve, reject) => {
            if(!this.points) {
               return []
            }

            let self = this
            let fetchPath = this.$root.apiRec + "blocks/filter"
            let pointNamesHotBlock = this.points.map(p => p.reportName)
            let pointNames = this.points.map(p => 'masterDev-' + p.reportName)
            let fromStr  = moment(this.curDateObject.date).format("YYYY-MM-DD HH:mm:00")
            let toStr = moment(this.curDateObject.toDate).add(-1, 'm').format("YYYY-MM-DD HH:mm:00")

            let fromYearStr = this.curDateObject.dateStrFull.substring(0,4)
            let toYearStr = moment(this.curDateObject.toDate).add(-1, 'm').format("YYYY-MM-DD HH:mm:00").substring(0,4)

            let filterArr = []
            let PAGE_SIZE = 3
            let numOfPointBreak = Math.ceil(this.points.length/PAGE_SIZE)
            for(let i = 0;i<numOfPointBreak; i++){
               let pickedPointNames = pointNames.slice(i*PAGE_SIZE, Math.min(pointNames.length, (i+1)*PAGE_SIZE))
               let pickedPointNamesHotBlock = pointNamesHotBlock.slice(i*PAGE_SIZE, Math.min(pointNames.length, (i+1)*PAGE_SIZE))
               
               if (fromYearStr == toYearStr) {
                  self.loading = true
                  let f = {
                     includeRec: 'true',
                     from: fromStr,
                     to: toStr,
                     pointNames: pickedPointNames
                  }
                  if(pointNames.length<20){
                     f.pointNamesHotBlock = pickedPointNamesHotBlock
                  }
                  filterArr.push(f)
               } else {
                  let f1 = {
                     includeRec: 'true',
                     from: fromStr,
                     to: moment(fromStr)
                        .endOf("year")
                        .format("YYYY-MM-DD 23:59:59"),
                     pointNames: pickedPointNames
                  }
                  let f2 = {
                     includeRec: 'true',
                        from: moment(toStr)
                           .startOf("year")
                           .format("YYYY-MM-DD 00:00:00"),
                        to: toStr,
                        pointNames: pickedPointNames
                  }

                  if(pointNames.length<20){
                     f2.pointNamesHotBlock = pickedPointNamesHotBlock
                  }

                  filterArr.push(f1)
                  filterArr.push(f2)
               }
            }

            self.loading = true
            let returnBlocks = []

            //console.log(filterArr)

            this.blocks = []

            for(let i = 0; i< filterArr.length; i++){
               try {
                  this.callApiOverOTA(
                     "PUT",
                     fetchPath,
                     filterArr[i]
                     )
               /*      
                  let iRes = await self.axios.put(
                        fetchPath, 
                        filterArr[i], 
                        {headers: { "x-auth": self.token }})
                  //console.log(iRes)
                  if (iRes.data && iRes.data.status === "OK") {
                     returnBlocks = returnBlocks.concat(iRes.data.content)
                     //console.log("returnBlocks length " + returnBlocks.length)
                  } else {
                     if(i==0 && iRes.data){
                        alert(iRes.data.message)
                     }
                  }
               */
                  
               } catch (err) {
                  console.log(err)
                  if(i==0){
                     alert(err)
                  }
               }
            }
            
            self.loading = false
            return returnBlocks
      },
      processDataBlocksAfterFetch () {
         this.pointsWithData = []
         // curDateObject
         if(this.points.length<=0) return
         // chart
         this.chartLabels = this.buildLabels()
         // let beginBuild = new Date()
         this.points.forEach((p, index) => {
            this.pointsWithData.push({
               reportName: p.reportName,
               displayName: p.name + " (" + p.unit + ")",
               color: p.color,
               backgroundColor: p.backgroundColor,
               active: p.active,
               dataItems: this.takeDateForPoints(this.blocks, p.reportName, index, p)
            })
         })
         // table  
         this.tableHeader = [
            {
               text: 'Date',
               align: 'start',
               sortable: true,
               value: 'date',
               width: 150,
            }]

         this.points.forEach((point) => {
            this.tableHeader.push({
               text: point.name + '(' + point.unit + ')',
               align: 'end',
               value: point.reportName
            })
         })
         this.tableItems = []
         this.pointsWithData.forEach((point) => {
            point.dataItems.forEach((dataItem, rIndex) => {
               if(!this.tableItems[rIndex]){
                  let row = {
                     date: moment(this.curDateObject.date).add(this.activeInterval*rIndex, 'minutes').format("DD-MM-YYYY HH:mm")
                  }
                  row[point.reportName] = dataItem
                  this.tableItems.push(row)
                  
               } else {
                  if(point.reportName.startsWith('airCon')){
                      this.tableItems[rIndex][point.reportName] = point.slace==dataItem?'On':'Off'
                  } else {
                     this.tableItems[rIndex][point.reportName] = dataItem
                  }
                  
               }
            })
         })
         if(this.reportType==0){
            this.exportExcel()
         }
         console.log("End fetch data...")
         this.$forceUpdate()
      },
      onSelectPointChanged () {
         if(!this.pointsWithData || this.pointsWithData.length<=0) return
         this.points.forEach(p => {
            let matchPoint = this.pointsWithData.find(pWithData => pWithData.reportName == p.reportName)
            if(matchPoint){
               matchPoint.active = p.active
            }
         })
         this.loadIndex = this.loadIndex + 1
      },
      async buildReport (reportType) {
         this.reportType = reportType
         if(reportType==0){
            this.showChart = false
            this.showTable = false
         }
         if(reportType==1){
            this.showChart = this.isBuildChart
            this.showTable = this.isBuildTable
         }
         this.fetchData()
         //console.log('----------- Fetch result ---------------')
         //console.log(results)
        /* 
         this.pointsWithData = []
         // curDateObject
         if(this.points.length<=0) return
         // chart
         this.chartLabels = this.buildLabels()
         // let beginBuild = new Date()
         this.points.forEach((p, index) => {
            this.pointsWithData.push({
               reportName: p.reportName,
               displayName: p.name + " (" + p.unit + ")",
               //color: (this.chartColors.length-1<index?'#111111': this.chartColors[index]),
               color: p.color,
               active: p.active,
               dataItems: this.takeDateForPoints(results, p.reportName, index)
            })
         })
         // table  
         this.tableHeader = [
            {
               text: 'Date',
               align: 'start',
               sortable: true,
               value: 'date',
               width: 150,
            }]

         this.points.forEach((point) => {
            this.tableHeader.push({
               text: point.name + '(' + point.unit + ')',
               align: 'end',
               value: point.reportName
            })
         })
         this.tableItems = []
         this.pointsWithData.forEach((point) => {
            point.dataItems.forEach((dataItem, rIndex) => {
               if(!this.tableItems[rIndex]){
                  let row = {
                     date: moment(this.curDateObject.date).add(this.activeInterval*rIndex, 'minutes').format("DD-MM-YYYY HH:mm")
                  }
                  row[point.reportName] = dataItem
                  this.tableItems.push(row)
                  
               } else {
                  this.tableItems[rIndex][point.reportName] = dataItem
               }
            })
         })
         if(reportType==0){
            this.exportExcel()
         }
         console.log("End fetch data...")
         this.$forceUpdate()
         */
      },
      buildLabels () {
         let labels = []
         let startDate = this.curDateObject.date
         let endDate = new Date()
         let numberOfItems = 0
         if(this.curDateObject.type == 'day') {
            endDate = moment(startDate).add(1, 'days').toDate()
            numberOfItems = Math.floor((endDate.getTime()-startDate.getTime())/(this.activeInterval*60000))
            for(let i=0; i<numberOfItems; i++){
               let dateStr = moment(startDate).add(this.activeInterval*i, 'minutes').format('HH:mm')
               labels.push(dateStr)
            }
         }

         if(this.curDateObject.type == 'week') {
            endDate = moment(startDate).add(7, 'days').toDate()
            numberOfItems = Math.floor((endDate.getTime()-startDate.getTime())/(this.activeInterval*60000))
            for(let i=0; i<numberOfItems; i++){
               let dateStr = moment(startDate).add(this.activeInterval*i, 'minutes').format('DD HH:mm')
               labels.push(dateStr)
            }
         }

         if(this.curDateObject.type == 'month') {
            endDate = moment(startDate).add(1, 'months').toDate()
            numberOfItems = Math.floor((endDate.getTime()-startDate.getTime())/(this.activeInterval*60000))
            for(let i=0; i<numberOfItems; i++){
               let dateStr = moment(startDate).add(this.activeInterval*i, 'minutes').format('DD HH') + 'h'
               labels.push(dateStr)
            }
         }

         if(this.curDateObject.type == 'year') {
            endDate = moment(startDate).add(1, 'years').toDate()
            numberOfItems = Math.floor((endDate.getTime()-startDate.getTime())/(this.activeInterval*60000))
            for(let i=0; i<numberOfItems; i++){
               let dateStr = moment(startDate).add(this.activeInterval*i, 'minutes').format('DD/MM')
               labels.push(dateStr)
            }
         }

         if(this.curDateObject.type == 'range') {
            endDate = this.curDateObject.toDate
            numberOfItems = Math.floor((endDate.getTime()-startDate.getTime())/(this.activeInterval*60000)) + 1

            for(let i=0; i<numberOfItems; i++){
               let dateStr = moment(startDate).add(this.activeInterval*i, 'minutes').format('DD HH:mm')
               labels.push(dateStr)
            }
         }
         console.log(numberOfItems)
         return labels
      },
      takeDateForPoints(items, reportName, pointIndex, point) {
         let retItem = []
         let blockItems = items.filter(p => p.name == reportName)
         if(blockItems && blockItems.length>0){

            // console.log(blockItems)
            let recItems = []
            blockItems.forEach((block) => {
               let blockDate = new Date(block.date)
               
               if(block.records && block.records.length>0){
                  block.records.forEach(rec => {
                     let recDate = moment(blockDate).add(rec.m, 'minutes').toDate()
                     if(rec.q == 0){
                        let rValue = rec.v
                        if(point.scale != undefined){
                           rValue = rValue * point.scale
                        }
                        if(rValue == 0) rValue = null
                        recItems.push({
                           date: recDate,
                           dateInMili: recDate.getTime(),
                           value: rValue
                           // value: Math.round(Math.random()*100)+100*pointIndex
                        })
                     } else {
                        recItems.push({
                           date: recDate,
                           dateInMili: recDate.getTime(),
                           value: null
                           // value: Math.round(Math.random()*100)+100*pointIndex
                        })
                     }
                  })
               }
            })
            console.log(pointIndex)
            let startDate = this.curDateObject.date
            let endDate = this.curDateObject.toDate
            let startDateMili  = startDate.getTime()
            let intervalInMili = this.activeInterval*60000
            let numberOfItems = Math.floor((endDate.getTime()-startDate.getTime())/intervalInMili)
            for(let i=0; i<numberOfItems; i++){
               // items.push(Math.round(Math.random()*200)+500 + index*100)
               let matchItem = recItems.find(item => item.dateInMili == startDateMili+intervalInMili*i)
               if(matchItem){
                  retItem.push(matchItem.value)
               } else {
                  retItem.push(null)
               }
            }
         }
         
         return retItem
      },
      exportExcel() {
         let wb = XLSX.utils.book_new();
         let data = this.tableItems;
         let header = this.tableHeader.map((theader) => theader.text)
         data = data.map(row => {
            let returnRow = {}
            returnRow[header[0]] = row['date']
            this.points.forEach(point => {
               returnRow[point.name + '(' + point.unit + ')'] = row[point.reportName]
            })
            return returnRow
         })

         let dataWS = XLSX.utils.json_to_sheet(data, {
            header: header
         });
         let wscols = [
            {wpx: 160}
         ]
         header.forEach((f, index) => {if(index>0) wscols.push({wpx: 120})})
         dataWS['!cols'] = wscols
         XLSX.utils.book_append_sheet(wb, dataWS, "Report");
         const fileName = this.genExcelFileName(this.dateRange);
         XLSX.writeFile(wb, fileName);
      },
      genExcelFileName() {
         let filename = ""   
         if(this.points.length>0){
            filename = this.points[0].reportName
         }
         filename = filename +
         '-' +
         new moment(this.curDateObject.date).format("DD-MM-YYYY") +
         ".xlsx";
         return filename;
      },
      callApiOverOTA (method, url, body) {
         this.$socket.emit('callHTTPRequest', 
         { 
            devCode: this.$root.devCode,
            askKey: this.socketAskKey,
            method: method,
            url: url,
            body: body
         })
      }
   },
   mounted() {
      this.$nextTick(() => {
      })
   },
}
</script>

<style>
   .reportLabel {
      font-size: 18px;
      line-height:40px;
   }
</style>